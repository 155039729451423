<template>
  <section class="container px-3 pt-24 pb-16 mx-auto sm:px-0">
    <p
      class="
        mb-2
        text-sm
        font-semibold
        leading-6
        tracking-wider
        text-justify text-blue-gray-800
      "
    >
      SGUniStats | Legal
    </p>
    <h1 class="mb-3 text-2xl font-bold leading-7 text-blue-gray-800">
      Privacy Policy
    </h1>

    <div class="leading-7 tracking-tight text-justify">
      <p class="mb-7">
        This Privacy Policy describes how your personal information is
        collected, used, and shared when you visit sgunistats.com (the “Site”).
      </p>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          PERSONAL INFORMATION WE COLLECT
        </h2>

        <p class="mb-3">
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages, what websites or search terms referred
          you to the Site, and information about how you interact with the Site.
          We refer to this automatically-collected information as “Device
          Information.”
        </p>

        <p class="mb-3">
          We collect Device Information using the following technologies:
        </p>

        <ul class="mb-3 ml-2 space-y-2">
          <li>
            - “Cookies” are data files that are placed on your device or
            computer and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, visit
            <a
              href="http://www.allaboutcookies.org"
              class="sm:hover:underline text-sky-800"
              target="_blank"
              rel="noopener noreferrer"
              >http://www.allaboutcookies.org</a
            >.
          </li>
          <li>
            - “Log files” track actions occurring on the Site, and collect data
            including your IP address, browser type, Internet service provider,
            referring/exit pages, and date/time stamps.
          </li>
          <li>
            - “Web beacons,” “tags,” and “pixels” are electronic files used to
            record information about how you browse the Site.
          </li>
        </ul>

        <p class="mb-3">
          Additionally, third party vendors, including Google, use cookies to
          serve ads based on a your prior visits to sgunistats.com or other
          websites.
        </p>

        <p class="mb-3">
          Google's use of advertising cookies enables it and its partners to
          serve ads to you based on their visit to sgunistats.com and/or other
          sites on the Internet.
        </p>

        <p class="mb-3">
          You may opt out of personalized advertising by visiting
          <a
            href="https://www.google.com/settings/ads"
            class="sm:hover:underline text-sky-800"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ads Settings</a
          >. Alternatively, you can opt out of a third-party vendor's use of
          cookies for personalized advertising by visiting
          <a
            href="https://www.aboutads.info"
            class="sm:hover:underline text-sky-800"
            target="_blank"
            rel="noopener noreferrer"
            >www.aboutads.info</a
          >.
        </p>

        <p class="mb-3">
          When we talk about “Personal Information” in this Privacy Policy, we
          are talking about Device Information.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          HOW DO WE USE YOUR PERSONAL INFORMATION?
        </h2>

        <p class="mb-3">
          We use the Device Information that we collect to help us screen for
          potential risk (in particular, your IP address), and more generally to
          improve and optimize our Site (for example, by generating analytics
          about how our users interact with the Site).
        </p>

        <p class="mb-3">
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above. We also use Google
          Analytics to help us understand how our users use the Site--you can
          read more about how Google uses your Personal Information here:
          <a
            href="https://www.google.com/intl/en/policies/privacy/"
            class="sm:hover:underline text-sky-800"
            target="_blank"
            rel="noopener noreferrer"
            >https://www.google.com/intl/en/policies/privacy/</a
          >.
        </p>

        <p class="mb-3">
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          BEHAVIOURAL ADVERTISING
        </h2>

        <p class="mb-3">
          As described above, we use your Personal Information to provide you
          with targeted advertisements or marketing communications we believe
          may be of interest to you. For more information about how targeted
          advertising works, you can visit the Network Advertising Initiative’s
          (“NAI”) educational page at
          <a
            href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
            class="sm:hover:underline text-sky-800"
            target="_blank"
            rel="noopener noreferrer"
            >http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a
          >.
        </p>

        <p class="mb-3">
          You can opt out of targeted advertising through:
          <a
            href="https://www.google.com/settings/ads/anonymous"
            class="sm:hover:underline text-sky-800"
            target="_blank"
            rel="noopener noreferrer"
            >https://www.google.com/settings/ads/anonymous</a
          >
        </p>

        <p class="mb-3">
          - Additionally, you can opt out of some of these services by visiting
          the Digital Advertising Alliance’s opt-out portal at:
          <a
            href="http://optout.aboutads.info/"
            class="sm:hover:underline text-sky-800"
            target="_blank"
            rel="noopener noreferrer"
            >http://optout.aboutads.info/</a
          >.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-4
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          CHANGES
        </h2>

        <p class="mb-3">
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>
      </div>

      <div class="mb-8">
        <h2
          class="
            mb-2
            text-lg
            font-bold
            tracking-normal
            text-left text-blue-gray-800
          "
        >
          More
        </h2>
        <router-link
          class="underline sm:hover:text-sky-800"
          :to="{ name: 'terms' }"
          >Terms and Conditions</router-link
        >
      </div>
    </div>
  </section>
</template>
